import { FC, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
const jsDemoImagesTransform = document.createElement("script");
jsDemoImagesTransform.type = "text/javascript";
jsDemoImagesTransform.src =
  "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
document.head.appendChild(jsDemoImagesTransform);
require("@wiris/mathtype-tinymce6");

const config = {
  external_plugins: {
    tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
  },
  plugins:
    "anchor autolink charmap codesample code emoticons image link lists media searchreplace table visualblocks wordcount",

  toolbar:
    "undo redo | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry code | fontfamily fontsize forecolor  | bold italic underline strikethrough |  blocks  link image media table mergetags | align lineheight | tinycomments |  numlist bullist indent outdent | emoticons charmap | removeformat",
  // paste_as_text: true,
  // htmlAllowedTags: [".*"],
  // htmlAllowedAttrs: [".*"],
  extended_valid_elements: "*[.*]",
  font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
  font_size_input_default_units: "px",
  content_style:
    "@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Roboto');  body { font-family: 'Nunito', sans-serif; color:'#141F25'; font-size: 16px; } ",
  font_family_formats: "Nunito=nunito; Roboto=roboto;",

  //   color_map: [
  //   '000000', 'Black',
  //   '808080', 'Gray',
  //   'FFFFFF', 'White',
  //   'FF0000', 'Red',
  //   'FFFF00', 'Yellow',
  //   '008000', 'Green',
  //   '0000FF', 'Blue'
  // ]
};

type Props = {
  value: string;
  onChange: (value: string) => void;
  height?: number | string;
  width?: number | string;
};
const MotiroEditor: FC<Props> = ({
  value,
  onChange,
  height = 501,
  width = "100%",
}) => {
  const [options] = useState({ ...config, height, width });
  const [content, setContent] = useState(value);
  const editorRef = useRef<Editor>(null);
  const teste = (e) => {
    onChange(e);
    setContent(e);
  };
  return (
    <Editor
      apiKey="t2k9rfa7defjrl8m14s9ei8dw8xzx5f0h5d967urr8trr9zj" //Token API Tiny
      ref={editorRef}
      value={content}
      init={options}
      onEditorChange={teste}
      onBeforePaste={(e) => {
        console.log(e);
      }}
      onPaste={(e) => {
        console.log(e);
      }}
    />
  );
};

export default MotiroEditor;
